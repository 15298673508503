import {useState, useEffect} from 'react'

import Select from '../../../components/form/Select'
import styles from './Filtro.module.css'

function Filtro() {

    const [jobs, setJobs] = useState([])

    useEffect(() => {
        fetch("http://localhost:5000/jobs")
        .then(response => response.json())
        .then((data) => {
            setJobs(data)
        })
        .catch((err) => console.log(err))
       
    }, [])

    return (
        <form className={styles.box_filter}>
            <Select text="Cliente" name="cliente" options={jobs} />
            <Select text="Campanha" name="campanha" options={jobs} />
            <Select text="Status" name="status" options={jobs} />
            <Select text="Ordenação" name="ordenacao" />
        </form> 
    )
}

export default Filtro